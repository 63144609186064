import React, {Component} from 'react';
import {empty} from "../../helpers/generalHelper";
import {message} from "antd";
import {apiCall} from "../../helpers/networkHelper";
import {SIGNIN} from "../../utils/endpoints";
import ErrorHandler from "../../classes/ErrorHandler";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {signIn} from "../../actions";

class Login extends Component {

    constructor (props) {
        super (props)

        this.state = {
            email: '',
            password: ''
        };
    }

    onChangeEmail = e => {
        this.setState({email: e.target.value});
    };

    onChangePassword = e => {
        this.setState({password: e.target.value});
    };

    onSubmitSignIn = e => {
        e.stopPropagation();
        e.preventDefault();

        const {
            email,
            password
        } = this.state;
        let validForm = true;

        if(empty(email)){
            message.error('Please enter your email address');
            validForm = false;
        }

        if(empty(password)){
            message.error('Please enter your password, 8 characters minimum');
            validForm = false;
        }

        const formData ={
            email,
            password
        };

        if(validForm){
            apiCall(
                SIGNIN,
                'post',
                formData,
                (res) => {
                    localStorage.setItem('access_token', res.data.access_token);
                    this.props.signIn();

                    message.success('You have successfully signed in');

                    setTimeout(() => {
                        window.location.href = '/';
                    }, 1000);
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                }
            );
        }

    };

    render (){
        const {email, password} = this.state;

        return (
            <div>
                {/*<Breadcrumb title={'Login'}/>*/}

                {/*Login section*/}
                <section className="login-page section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <h3>Sign In</h3>
                                <div className="theme-card">
                                    <form className="theme-form" onSubmit={this.onSubmitSignIn}>
                                        <div className="form-group">
                                            <label htmlFor="email">Email</label>
                                            <input type="text"
                                                   className="form-control"
                                                   id="email"
                                                   placeholder="Email"
                                                   value={email}
                                                   onChange={this.onChangeEmail}
                                                   required={true} />
                                        </div>
                                        <div className="form-group">
                                            <label htmlFor="review">Password</label>
                                            <input type="password"
                                                   className="form-control"
                                                   id="password"
                                                   placeholder="Enter your password"
                                                   value={password}
                                                   onChange={this.onChangePassword}
                                                   required={true} />
                                        </div>
                                        <div>
                                            <button type="submit" className="btn btn-solid">Sign In</button>
                                            <Link style={{float: "right"}} to={"/forgot-password"}>Forgot Password?</Link>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-lg-6 right-login">
                                <h3>New Customer</h3>
                                <div className="theme-card authentication-right">
                                    <h6 className="title-font">Create an Account</h6>
                                    <p>Sign up for a free account at our store. Registration is quick and easy. It
                                        allows you to be able to order from our shop. To start shopping click
                                        register.</p>
                                    <Link to="/sign-up" className="btn btn-solid">Sign Up</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

const mapStateToProps = (state) => ({
    authenticated: state.auth.authenticated
});

export default connect(
    mapStateToProps,
    {signIn}
)(Login)
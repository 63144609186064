import React, { Component } from 'react';
import { Magnifier, MOUSE_ACTIVATION, TOUCH_ACTIVATION } from 'react-image-magnifiers';

export default class ImageZoom extends Component {
    render() {
        const {image} = this.props;

        return (
            <Magnifier
                imageSrc={image}
                imageAlt="Product Image"
                // largeImageSrc={image} // Optional
                mouseActivation={MOUSE_ACTIVATION.CLICK} // Optional
                touchActivation={TOUCH_ACTIVATION.TAP} // Optional
                dragToMove={false}
                className="img-fluid image_zoom_cls-0"
                // style={{objectFit: 'cover'}}
            />
            // <img src={`${image}`}  className="img-fluid image_zoom_cls-0" />
        );
    }
}
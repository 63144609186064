import {IMAGE_BASE_URL} from "../utils/constants";

export function empty(mixedVar) {
    let undef;
    let key;
    let i;
    let len;
    let emptyValues = [undef, null, false, 0, '', '0'];

    for (i = 0, len = emptyValues.length; i < len; i++) {
        if (mixedVar === emptyValues[i]) {
            return true
        }
    }

    if (typeof mixedVar === 'object') {
        for (key in mixedVar) {
            if (mixedVar.hasOwnProperty(key)) {
                return false
            }
        }
        return true
    }

    return false
}

export const process_image = (image_url) => {
    if (image_url.indexOf("http://") !== 0 && image_url.indexOf("https://") !== 0) {
        return IMAGE_BASE_URL+image_url;
    }
};

// export const removeSplashScreen = () => {
//     const ele = document.getElementById('ipl-progress-indicator');
//     if(ele){
//         // fade out
//         ele.classList.add('available');
//         setTimeout(() => {
//             // remove from DOM
//             ele.outerHTML = ''
//         }, 2000)
//     }
// };

export function getUrlParam(name, url) {
    if (!url) url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
}
import React, {Component} from 'react';
import styles from './ModuleNewsletter.module.scss';
import {Link} from "react-router-dom";
import {svgFreeShipping, svgoffer, svgservice} from "../services/script";

class ModuleNewsletter extends Component {
    render() {
        return (
            <React.Fragment>
                <div className="container-fluid" style={{background: 'white'}}>
                    <section className={`service border-section small-section ${styles.serviceSection}`}>
                        <div className="row">
                            <div className="col-md-4 service-block">
                                <Link to="/stores">
                                    <div className="media">
                                        <div dangerouslySetInnerHTML={{ __html: svgFreeShipping }} />
                                        <div className="media-body">
                                            <h4>store pickup</h4>
                                            <p>choose our nearby outlets</p>
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgservice }} />
                                    <div className="media-body">
                                        <h4>fast process</h4>
                                        <p>within 1x24 hour guarantee</p>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4 service-block">
                                <div className="media">
                                    <div dangerouslySetInnerHTML={{ __html: svgoffer }} />
                                    <div className="media-body">
                                        <h4>generous offers</h4>
                                        <p>enjoy our seasonal promo</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>

                <div>
                    <div className="container">
                        <section className={`small-section border-section border-top-0 ${styles.borderSection}`}>
                            <div className="row">
                                <div className={`col-lg-12 ${styles.newsletterWrapper}`}>
                                    <div className={styles.sectionTitle}>Subscribe to Our Newsletter</div>
                                    <form className={styles.newsletterForm}>
                                        <input type="text"
                                               className={styles.newsletterInput}
                                               id="exampleFormControlInput1"
                                               placeholder="Enter your email"/>
                                        <div>
                                            <button type="submit" className="btn btn-solid">subscribe</button>
                                        </div>
                                    </form>
                                </div>

                            </div>

                            <div className="row">
                                <div className="col-lg-12">
                                    <div className={styles.storeWrapper}>
                                        <div className={styles.sectionTitle}>Download Our App</div>
                                        <div className={styles.storeIcon}>
                                            <a href="#">
                                                <img className={styles.playstoreImg} src={`${process.env.PUBLIC_URL}/assets/images/wm/google-play-badge.png`} />
                                            </a>
                                            <a href="#">
                                                <img className={styles.appstoreImg} src={`${process.env.PUBLIC_URL}/assets/images/wm/app-store-badge.svg`} />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </section>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default ModuleNewsletter;
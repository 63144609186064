import React, {Component} from 'react';
import {withTranslate} from 'react-redux-multilingual'

// Custom Components
import HeaderOne from './common/headers/header-one';
import HeaderTwo from './common/headers/header-two';
import HeaderThree from './common/headers/header-three';

import FooterOne from "./common/footers/footer-one";
import FooterTwo from "./common/footers/footer-two";
import FooterThree from "./common/footers/footer-three";

// ThemeSettings
import ThemeSettings from "./common/theme-settings"
import Header from "./Header";


class App extends Component {

    render() {

        return (
            <div>
                <Header/>
                <div>{/*style={{paddingTop: 60}}*/}
                    {this.props.children}
                </div>
                <FooterOne logoName={'logo.png'}/>
            </div>
        );
    }
}

export default withTranslate(App);
import React, {Component} from 'react';
import {message, Modal, Spin} from "antd";
import {empty} from "../helpers/generalHelper";
import {apiCall} from "../helpers/networkHelper";
import {
    ACCOUNT_CHANGE_PASSWORD,
    ACCOUNT_PROFILE,
    USER_NEWSLETTER_SUBSCRIBE,
    USER_NEWSLETTER_UNSUBSCRIBE
} from "../utils/endpoints";
import ErrorHandler from "../classes/ErrorHandler";
import AccountMenu from "./atoms/AccountMenu";
import {Link} from "react-router-dom";

class AccountChangePassword extends Component {

    constructor(props) {
        super(props);

        this.state = {
            pageLoading: false,
            old_password: '',
            new_password: '',
            new_password_conf: ''
        };

        this.emptyForm = {
            old_password: '',
            new_password: '',
            new_password_conf: ''
        };
    }

    onChangeOldPassword = e => {
        this.setState({old_password: e.target.value});
    };

    onChangeNewPassword = e => {
        this.setState({new_password: e.target.value});
    };

    onChangeNewPasswordConf = e => {
        this.setState({new_password_conf: e.target.value});
    };

    onSubmitChangePassword = (e) => {
        e.stopPropagation();
        e.preventDefault();

        const {
            old_password,
            new_password,
            new_password_conf
        } = this.state;
        let validForm = true;

        if (empty(old_password)) {
            message.error('Please enter the old password');
            validForm = false;
        }

        if (empty(new_password)) {
            message.error('Please enter the new password');
            validForm = false;
        }

        if (empty(new_password_conf)) {
            message.error('Please enter the new password confirmation');
            validForm = false;
        }

        if(validForm && new_password !== new_password_conf){
            message.error('New password doesn\'t match the confirmation.');
            validForm = false;
        }

        const formData = {
            old_password,
            new_password,
            new_password_conf
        };

        if (validForm) {
            this.setState({pageLoading: true});
            apiCall(
                ACCOUNT_CHANGE_PASSWORD,
                'post',
                formData,
                (res) => {
                    message.success(res.data.message);
                    this.setState({...this.emptyForm});
                },
                (err) => {
                    ErrorHandler.handleGeneralError(err);
                },
                res => {
                    this.setState({
                        pageLoading: false
                    });
                }
            );
        }
    };

    render() {
        const {pageLoading, old_password, new_password, new_password_conf} = this.state;

        return (
            <div>
                {/*Dashboard section*/}
                <section className="section-b-space">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-3">
                                <AccountMenu activeMenu="change-password" isMobile={true}/>

                                <div className="dashboard-left">
                                    <div className="collection-mobile-back">
                                    <span className="filter-back">
                                        <i className="fa fa-angle-left" aria-hidden="true"></i> back
                                    </span>
                                    </div>
                                    <div className="block-content">
                                        <AccountMenu activeMenu="change-password"/>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-9">
                                <div className="dashboard-right">
                                    <div className="dashboard">
                                        {/*<div className="page-title">
                                            <h2>My Dashboard</h2>
                                        </div>
                                        <div className="welcome-msg">
                                            <p>Hello, MARK JECNO !</p>
                                            <p>From your My Account Dashboard you have the ability to view a snapshot of
                                                your recent account activity and update your account information. Select
                                                a link below to view or edit information.</p>
                                        </div>*/}
                                        <div className="box-account box-info">
                                            <div className="box-head">
                                                <h2 style={{marginTop: 0, marginBottom: 30}}>Change Password</h2>
                                            </div>
                                            <Spin spinning={pageLoading} tip="Loading..">

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="box">
                                                            <div className="box-content">
                                                                <form className="theme-form"
                                                                      onSubmit={this.onSubmitChangePassword}>
                                                                    <div className="form-row">
                                                                        <div className="col-md-12">
                                                                            <div style={{marginBottom: 20}}>
                                                                                <label style={{fontWeight: 700}}
                                                                                       htmlFor="old_password">Old Password</label>
                                                                                <input type="password"
                                                                                       className="form-control"
                                                                                       id="old_password"
                                                                                       placeholder="Enter your old password"
                                                                                       value={old_password}
                                                                                       onChange={this.onChangeOldPassword}
                                                                                       required/>
                                                                            </div>

                                                                            <div style={{marginBottom: 20}}>
                                                                                <label style={{fontWeight: 700}}
                                                                                       htmlFor="new_password">New Password</label>
                                                                                <input type="password"
                                                                                       className="form-control"
                                                                                       id="new_password"
                                                                                       placeholder="Enter your new password"
                                                                                       value={new_password}
                                                                                       onChange={this.onChangeNewPassword}
                                                                                       required/>
                                                                            </div>

                                                                            <div style={{marginBottom: 20}}>
                                                                                <label style={{fontWeight: 700}}
                                                                                       htmlFor="new_password_conf">New Password Confirmation</label>
                                                                                <input type="password"
                                                                                       className="form-control"
                                                                                       id="new_password_conf"
                                                                                       placeholder="Confirm your new password"
                                                                                       value={new_password_conf}
                                                                                       onChange={this.onChangeNewPasswordConf}
                                                                                       required/>
                                                                            </div>

                                                                            <div style={{marginBottom: 20}}>
                                                                                <button type="submit" className="btn btn-solid">Change Password</button>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </form>
                                                                {/*<h6><a href="#">Change Password</a></h6>*/}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Spin>
                                            {/*<div>
                                                <div className="box">
                                                    <div className="box-title">
                                                        <h3>Address Book</h3>
                                                        <a href="#">Manage Addresses</a>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <h6>Default Billing Address</h6>
                                                            <address>
                                                                You have not set a default billing address.<br/>
                                                                <a href="#">Edit Address</a>
                                                            </address>
                                                        </div>
                                                        <div className="col-sm-6">
                                                            <h6>Default Shipping Address</h6>
                                                            <address>
                                                                You have not set a default shipping address.<br />
                                                                <a href="#">Edit Address</a>
                                                            </address>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>*/}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>
        )
    }
}

export default AccountChangePassword
import React, {Component} from 'react';
import {Link} from 'react-router-dom';
import { withTranslate } from 'react-redux-multilingual'

class TopBar extends Component {

    render() {
        const {translate} = this.props;
        return (
            <div className="top-header top-header-dark3">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6">
                            <div className="header-contact">
                                <ul style={{
                                    marginBottom: 0
                                }}>
                                    <li style={{marginRight: 10}}>
                                        <Link to="/">
                                            <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-hammer-3-white.png`}
                                                 style={{
                                                     maxHeight: 13
                                                 }}
                                                 alt=""
                                                 className="img-fluid" />
                                        </Link>
                                    </li>
                                    <li style={{marginRight: 10}}>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-coconut-2-white.png`}
                                             style={{
                                                 maxHeight: 13
                                             }}
                                             alt=""
                                             className="img-fluid" />
                                    </li>
                                    <li>
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/wm/logo-nail-2-white.png`}
                                             style={{
                                                 maxHeight: 13
                                             }}
                                             alt=""
                                             className="img-fluid" />
                                    </li>
                                    {/*<li>{translate('topbar_title', { theme_name: ' Multikart' })}</li>*/}
                                    {/*<li><i className="fa fa-phone" aria-hidden="true"></i>{translate('call_us')}:  123 - 456 - 7890</li>*/}
                                </ul>
                            </div>
                        </div>
                        <div className="col-lg-6 text-right">
                            <ul className="header-dropdown" style={{marginBottom: 0}}>
                                {/*<li className="mobile-wishlist compare-mobile"><Link to={`${process.env.PUBLIC_URL}/compare`}><i className="fa fa-random" aria-hidden="true"></i>{translate('compare')}</Link></li>*/}
                                <li className="mobile-wishlist"><Link to={`${process.env.PUBLIC_URL}/wishlist`}><i className="fa fa-heart" aria-hidden="true"></i>{translate('wishlist')}</Link></li>
                                <li className="onhover-dropdown mobile-account">
                                    <i className="fa fa-user" aria-hidden="true"></i> {translate('my_account')}
                                    <ul className="onhover-show-div">
                                        <li>
                                            <Link to={`/sign-in`} data-lng="en">Login</Link>
                                        </li>
                                        <li>
                                            <Link to={`/sign-up`} data-lng="en">Register</Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default withTranslate(TopBar);
import shop from '../api/shop'
import * as types from '../constants/ActionTypes'
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import {apiCall} from "../helpers/networkHelper";
import {PROMOS, RETRIEVE_CART} from "../utils/endpoints";
import {message} from "antd";
import {empty} from "../helpers/generalHelper";
import {LOCAL_DEVICE_ID} from "../utils/constants";
import ErrorHandler from "../classes/ErrorHandler";

export const retrieveCart = () => async (dispatch) => {
    await apiCall(
        RETRIEVE_CART + `?local_device_id=${LOCAL_DEVICE_ID()}`,
        'get',
        null,
        (res) => {
            dispatch(retrieveCartThunk(types.RETRIEVE_CART, res.data.cart));
            // return {
            //     type: types.RETRIEVE_CART,
            //     cart: res.data.cart
            // };
        },
        (res) => {
            ErrorHandler.handleGeneralError(res);
        }
    );

    // dispatch(addToCartUnsafe(product, qty, promos));
};

export const retrieveCartThunk = (type, cart) => {
    return {
        type,
        cart
    };
};

export const openCart = () => {
    return {
        type: types.OPEN_CART
    };
};

export const closeCart = () => {
    return {
        type: types.CLOSE_CART
    };
};

export const signIn = () => {
    return {
        type: types.SIGN_IN
    };
};

export const signOut = () => {
    return {
        type: types.SIGN_OUT
    };
};




export const fetchProductsBegin = () => ({
    type: types.FETCH_PRODUCTS_BEGIN
});


export const receiveProducts = products => ({
    type: types.RECEIVE_PRODUCTS,
    products
})

export const getAllProducts = () => dispatch => {
    dispatch(fetchProductsBegin());
    shop.getProducts(products => {
        dispatch(receiveProducts(products));
        return products;
    })
}
export const fetchSingleProduct = productId => ({
    type: types.FETCH_SINGLE_PRODUCT,
    productId
})


//it seems that I should probably use this as the basis for "Cart"
export const addToCart = (product, qty, size='') => async (dispatch) => {
    let promos = [];
    await apiCall(
        PROMOS,
        'get',
        null,
        (res) => {
            promos = empty(res.data.product_promos)?[]:res.data.product_promos;
            dispatch(addToCartUnsafe(product, qty, promos, size));
            message.success("Item Added to Cart");
        },
        (res) => {
            console.log(res);
            message.error('Fail to retrieve product.');
        }
    );

    // dispatch(addToCartUnsafe(product, qty, promos));

};
export const addToCartAndRemoveWishlist = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty));
    dispatch(removeFromWishlist(product));
};
export const addToCartUnsafe = (product, qty, promos = [], size='') => {
    return {
        type: types.ADD_TO_CART,
        product,
        qty,
        promos,
        size
    }
};
export const removeFromCart = product_id => (dispatch) => {
    toast.error("Item Removed from Cart");
    dispatch({
        type: types.REMOVE_FROM_CART,
        product_id
    })
};
export const incrementQty = (product, qty) => (dispatch) => {
    toast.success("Item Added to Cart");
    dispatch(addToCartUnsafe(product, qty))

};
export const decrementQty = productId => (dispatch) => {
    toast.warn("Item Decrement Qty to Cart");

    dispatch({
        type: types.DECREMENT_QTY,
        productId
    })
};


//it seems that I should probably use this as the basis for "Wishlist"
export const addToWishlist = (product) => (dispatch) => {
    toast.success("Item Added to Wishlist");
    dispatch(addToWishlistUnsafe(product))

}
export const addToWishlistUnsafe = (product) => ({
    type: types.ADD_TO_WISHLIST,
    product
});
export const removeFromWishlist = product_id => (dispatch) => {
    toast.error("Item Removed from Wishlist");
    dispatch({
        type: types.REMOVE_FROM_WISHLIST,
        product_id
    })
};


//Compare Products
export const addToCompare = (product) => (dispatch) => {
    toast.success("Item Added to Compare");
    dispatch(addToCompareUnsafe(product))

}
export const addToCompareUnsafe = (product) => ({
    type: types.ADD_TO_COMPARE,
    product
});
export const removeFromCompare = product_id => ({
    type: types.REMOVE_FROM_COMPARE,
    product_id
});


// Filters
export const filterBrand = (brand) => ({
    type: types.FILTER_BRAND,
    brand
});
export const filterColor = (color) => ({
    type: types.FILTER_COLOR,
    color
});
export const filterPrice = (value) => ({
    type: types.FILTER_PRICE,
    value
});
export const filterSort = (sort_by) => ({
    type: types.SORT_BY,
    sort_by
});


// Currency
export const changeCurrency = (symbol) => ({
    type: types.CHANGE_CURRENCY,
    symbol
});

